.keyword {
  height: 30px;
}

.attack-snippet {
  height: 30px;
  width: 50px;
  line-height: 30px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  text-align: center;
  background-image: url(/lor/snippets/Attack.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.health-snippet {
  height: 30px;
  width: 50px;
  line-height: 30px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  text-align: center;
  background-image: url(/lor/snippets/Health.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.cost-snippet {
  height: 30px;
  width: 30px;
  line-height: 30px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  text-align: center;
  background-image: url(/lor/snippets/Cost.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
