.App {
}

h1 {
  color: white;
}

.ant-descriptions-bordered .ant-descriptions-view > table {
  table-layout: fixed;
}
